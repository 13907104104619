<template>
  <v-container id="dashboard" class="pb-0" fluid tag="section">
    <v-row dense>
      <v-col class="d-flex" cols="12">
        <v-tabs v-model="tab" height="36" background-color="rgba(0,0,0,0)">
          <v-tab class="px-0" :disabled="!countmon" @click="selectPrefix('mon')">
            <v-icon :color="countmon ? 'primary' : ''" size="24">mdi-book-outline</v-icon>
            ({{ countmon }})
          </v-tab>
          <v-tab class="px-0" :disabled="!countart" @click="selectPrefix('art')">
            <v-icon :color="countart ? 'primary' : ''" size="24">mdi-book-open-blank-variant</v-icon>
            ({{ countart }})
          </v-tab>
          <v-tab class="px-0" :disabled="!countath" @click="selectPrefix('ath')">
            <v-icon :color="countath ? 'primary' : ''" size="24">mdi-bank</v-icon>
            ({{ countath }})
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-btn-toggle v-model="selectedView" class="mb-1" dense mandatory>
          <v-btn icon>
            <v-icon>mdi-view-compact</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-view-module</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-view-list</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>
    <virtual-scroll :records="records" :collection="collection" :selected-view="selectedView" @reload="getRecords" />
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
import VirtualScroll from "../components/containers/VirtualScroll"

export default {
  name: "DashboardDashboard",
  components: { VirtualScroll },
  data() {
    return {
      loaded: false,
      domains: [],
      records: [],
      domain: "mon",
      collection: "marc_mon",
      viewType: "marc",
      totalResults: 1000000,
      itemsPerPage: 25,
      selectedView: 0,
      page: 1,
      filtersWhere: "",
      prefix: "mon",
      tab: null,
      countmon: 0,
      countart: 0,
      countath: 0
    }
  },

  computed: {
    appSchema() {
      return this.$store.getters.appSchema
    },
    baseURL() {
      return axios.defaults.baseURL
    }
  },
  watch: {
    $route() {
      //this.$log("route changed ", this.$route)
      if (this.$route.name == "CautareGenerala") {
        this.page = 1
        this.records = []
        this.setQuery(this.$route.query)
      }
    }
  },
  created() {
    this.$log("route to created search ", this.$route)
    this.setQuery(this.$route.query)
  },
  methods: {
    setQuery(query) {
      this.$log("setQuery ", query)
      if (query.q) {
        this.filtersWhere = '"$text": {"$search": "' + encodeURIComponent(query.q) + '"}'
      } else {
        this.filtersWhere = ""
      }
      this.getcountath()
      this.getcountart()
      this.getcountmon()
      if (query.prefix) {
        this.selectPrefix(query.prefix)
      } else {
        this.collection = "marc_mon"
        this.tab = 0
        this.getRecords()
        this.getRecords()
      }
    },
    selectPrefix(pre) {
      this.records = []
      this.page = 1
      this.prefix = pre
      if (this.prefix == "mon") {
        this.collection = "marc_mon"
        this.tab = 0
      } else if (this.prefix == "art") {
        this.collection = "marc_art"
        this.tab = 1
      } else if (this.prefix == "ath") {
        this.collection = "marc_ath"
        this.tab = 2
      }
      this.getRecords()
      this.getRecords()
    },
    getcountath() {
      let where = ""
      if (this.filtersWhere) {
        where = "where={" + this.filtersWhere + "}"
      }
      axios.get("ebib_marc_ath" + "?" + where).then(response => {
        this.countath = response.data._meta.total
      })
    },
    getcountart() {
      let where = ""
      if (this.filtersWhere) {
        where = "where={" + this.filtersWhere + "}"
      }
      axios.get("ebib_marc_art" + "?" + where).then(response => {
        this.countart = response.data._meta.total
      })
    },
    getcountmon() {
      let where = ""
      if (this.filtersWhere) {
        where = "where={" + this.filtersWhere + "}"
      }
      axios.get("ebib_marc_mon" + "?" + where).then(response => {
        this.countmon = response.data._meta.total
      })
    },
    getRecords() {
      this.loaded = false
      let where = ""
      if (this.filtersWhere) {
        where = "&where={" + this.filtersWhere + "}"
      }
      //this.$log("getr1 ", this.itemsPerPage)
      //this.$log("getr2 ", this.page)
      //this.$log("getr3 ", this.totalResults)
      if (this.itemsPerPage * (this.page - 1) <= this.totalResults) {
        //this.$log("getr4 ", this.page)
        axios
          .get("ebib_" + this.collection + "?max_results=" + this.itemsPerPage + "&page=" + this.page + where)
          .then(response => {
            //this.$log("response ", response)
            this.totalResults = response.data._meta.total
            response.data._items.map(itm => {
              const rec = {}
              rec._id = itm._id
              for (const itms in itm) {
                if (itms != "fields") {
                  rec[itms] = itm[itms]
                }
              }
              if (itm.fields["299"]) {
                rec.title = itm.fields["299"][0].a[0].val
              } else if (itm.fields["200"]) {
                rec.title = itm.fields["200"][0].a[0].val
                if (itm.fields["200"][0].c) {
                  rec.title += ". " + itm.fields["200"][0].c[0].val
                }
                if (itm.fields["200"][0].d) {
                  rec.title += " = " + itm.fields["200"][0].d[0].val
                }
                if (itm.fields["200"][0].h) {
                  rec.title += " " + itm.fields["200"][0].h[0].val
                }
                if (itm.fields["200"][0].i) {
                  rec.title += " : " + itm.fields["200"][0].i[0].val
                }
              } else {
                rec.title = ""
              }
              for (const flds in itm.fields) {
                for (const fld in itm.fields[flds]) {
                  for (const sub in itm.fields[flds][fld]) {
                    if (sub.length == 1) {
                      for (const subs in itm.fields[flds][fld][sub]) {
                        if (rec["fields." + flds + "." + sub + ".val"]) {
                          rec["fields." + flds + "." + sub + ".val"] += ", " + itm.fields[flds][fld][sub][subs].val
                        } else {
                          rec["fields." + flds + "." + sub + ".val"] = itm.fields[flds][fld][sub][subs].val
                        }
                      }
                    } else {
                      rec["fields." + flds + "." + sub] = itm.fields[flds][fld][sub]
                    }
                  }
                }
              }
              this.records.push({
                data: rec
              })
            })
          })
      }
      this.page++
    }
  }
}
</script>
<style></style>
